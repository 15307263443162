@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Outfit-Medium';
    src: url('./assets/fonts/Outfit-Medium.ttf');
}

@font-face {
    font-family: 'Outfit-Regular';
    src: url('./assets/fonts/Outfit-Regular.ttf');
}

@font-face {
    font-family: 'Aeonik';
    src: url('./assets/fonts/AeonikProTRIAL-Bold.otf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Outfit';
}

body {
    margin: 0;
    background-color: #fff !important;
    overflow-x: hidden;

}


*::-webkit-scrollbar {
    display: none;
}

select:required:invalid {
    color: #aab3bf !important;
}

option[value=''][disabled] {
    display: none;
}

.form-input > input {
    border: none !important;
}

.customScroll::-webkit-scrollbar {
    width: 8px;
    display: block;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 3px;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
    background: #eaecf0;
    border-radius: 3px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
    background: #0000009a;
}

.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #cccccc;
    -webkit-text-fill-color: #637178;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    font-weight: 500;
    color: #637178;
}

.form-input > input:-webkit-autofill,
.form-input > input:-webkit-autofill:hover,
.form-input > input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    color: #637178;
}

.form-input[aria-invalid='true'] {
    border: 1px solid #f1998e !important;
}

.form-input + input[aria-invalid='true'] {
    border: 1px solid #f1998e !important;
}

input:focus::placeholder {
    color: transparent !important;
}

textarea:focus::placeholder {
    color: transparent !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-tiny-popover-container {
    z-index: 4000;
}

.wave {
    width: 10px;
    height: 10px;
    background: linear-gradient(20deg, #047dde, #fff);
    margin: 1.5px;
    animation: wave 3s linear infinite;
    border-radius: 10px;
}

.grow {
    animation: grow 3s linear infinite;
}

.wave:nth-child(2) {
    animation-delay: 0.1s;
}

.wave:nth-child(3) {
    animation-delay: 0.2s;
}

.wave:nth-child(4) {
    animation-delay: 0.3s;
}

.wave:nth-child(5) {
    animation-delay: 0.4s;
}

.wave:nth-child(6) {
    animation-delay: 0.5s;
}

.wave:nth-child(7) {
    animation-delay: 0.6s;
}

.wave:nth-child(8) {
    animation-delay: 0.7s;
}

.wave:nth-child(9) {
    animation-delay: 0.8s;
}

.wave:nth-child(10) {
    animation-delay: 0.9s;
}

.settlement-service-header button {
    background-color: unset !important;
}

@keyframes wave {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes grow {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    /* Edge */
    color: #000000;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000000;
}

input::placeholder {
    color: #000000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}


:root {
    --primary-color: #0644CC;
    --primary-white: #ffffff;
    --primary-grey: #EEEEEE;
    --primary-hover-color: #123FAD;
    --hover-text-color: #184CCB;
    --outline-border-color: #353535;
    --outline-text-color: #353535;
    --header-black: #353542;
}

html, body {
    font-family: "Outfit";
}